@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;

.Menu_Content{
  @apply w-[90%]
}
.header-link {
  @apply text-xl font-extrabold font-press-start border-transparent border-b-4  text-gray-700   h-full hover:text-linkColorDark transition-all duration-300 hover:border-b-2 hover:border-linkColorDark  box-border text-center;
}

.Second__Blog {
  @apply w-[90%] md:flex relative z-0  bg-gradient-to-r font-press-start from-[#172940] to-[#64f] rounded-3xl mt-1;
}

.Service__Blog {
  @apply w-[90%] px-12 border md:flex relative z-0 py-20 bg-gradient-to-r font-press-start from-[#64f] to-[#172940] rounded-3xl mt-1 max-w-screen-xl mx-auto overflow-hidden;
}

.link-button {
  @apply py-2 sm:py-3 w-[140px] sm:w-[220px] capitalize flex justify-center lg:px-12 md:px-8 sm:px-6  border border-gray-100 rounded-3xl bg-[#172940] text-white transition-all duration-300 ease-linear  hover:text-linkColor font-press-start-lato;
}

.Home__Counter {
  @apply rounded-3xl bg-[#172940] py-10 max-w-screen-xl mx-auto flex px-12 space-x-4 bg-gradient-to-r from-[#64f] to-[#40f];
}

.Home__Counter__Child {
  @apply border h-16 rounded-lg px-6 flex justify-between items-center transition-all duration-500 hover:-translate-y-2 bg-white;
}

.Home__Counter__Child__Title {
  @apply text-[#172940]  text-base lg:text-xl font-press-start-lato;
}

.Link__Svg {
  @apply text-[#172940]  w-8 h-8 transition-all duration-500;
}

.Section_for_round {
  @apply  mx-auto md:w-full pb-36 pt-24 md:pt-24  bg-bck-radial relative mt-0 overflow-hidden;

}

.link-button-dark {
  @apply py-2 sm:py-3 w-[140px] sm:w-[220px] flex justify-center lg:px-12 md:px-8 sm:px-6  border border-black rounded-3xl bg-[#172940] text-white transition-all duration-300 ease-linear hover:bg-transparent hover:text-linkColor  font-press-start-lato;
}
.After__Rotate__Effect{
  @apply py-0 md:py-8
}
.Footer-Section{
  background: linear-gradient(172deg, #172940 20%, #64f 300%);
}
.About_Title{
  @apply text-center font-press-start text-black font-bold text-xl sm:text-3xl
}
.About_Desc{
  @apply mt-10 font-press-start-lato font-medium text-xl 
}
.Shadow{
  box-shadow: 0 10px 20px -30px rgb(0 0 0 / 15%), 0 20px 20px -20px rgb(23 41 64 / 25%);
}
.Slider_Part{
  @apply bg-white rounded-xl px-4 relative
}





.Slider_Part::after{
  position:absolute;
  content:"";
  left:0;
  top:18px;
  background:#00d1f6;
  width:2px;
  height:40%;
}
/*
.After__Rotate__Effect::after {
  position: absolute;
  content: "";
  top: 0%;
  left: 0%;
  width: 0%;
  height: 0%;
  background: linear-gradient(172deg, #172940 20%, #64f 300%);
  transform: rotate(0deg);
  border: 10px solid;
  border-image-slice: 1;
  border-width: 10px;
  border-image-source: linear-gradient(to left, #64f, #172940);
}
@media screen and (max-width:620px){
  .After__Rotate__Effect::after{
    transform: rotate(0deg);
  }
}
*/
.Last-Section{
  background: linear-gradient(172deg, #172940 20%, #64f 300%);
  
}
/*
.Second__Blog::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  clip-path: polygon(39% 0, 100% 0%, 100% 100%, 0 100%);
  background: #172940;
   z-index:9999999;
  width: 60%;
  height: 100%;
  overflow:hodden;
} */

@media screen and (max-width:1200px){
  .Second__Blog::after{
    width:70%;
  }
}
@media screen and (max-width:775px){
  .Second__Blog::after{
    width:90%;
  }
}

.Section_for_round::after {
  position: absolute;
  content: "";
  width: 120%;
  height: 90px;
  top: -40px;
  left: -10%;
  background: white;
  border-radius: 100%;
}

.Section_for_round::before {
  position: absolute;
  content: "";
  width: 120%;
  height: 90px;
  bottom: -40px;
  left: -10%;
  background: white;
  border-radius: 100%;
}
/*
.Last-Section::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: linear-gradient(172deg, #172940 20%, #64f 300%);
  z-index:0;
}
*/
.Service__Blog::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 73% 100%, 0% 100%);
  background: #172940;
  /* z-index:9999999; */
  width: 50%;
  height: 100%;
}

.Home__Counter__Child:hover .Link__Svg {
  @apply scale-x-125;
}

/*  */
.containerS {
  @apply max-w-full relative flex justify-center items-center;
}
.form-container {
  @apply flex justify-center w-[100%] shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)] relative pt-[60px] pb-[140px] px-[60px];
  flex-flow: wrap;
}
.form-line {
  @apply w-full block relative transition-[0.3s] duration-[all] ease-[ease] pt-2.5;
}
.form-line + .form-line {
  @apply mt-[30px];
}
.form-line label {
  @apply block absolute text-sm text-[#ccc] transition-[0.3s] duration-[all] ease-[ease] z-[1] left-0 top-[15px];
}
.form-input {
  @apply text-base  relative z-[2] bg-transparent transition-[0.3s] duration-[all] ease-[ease] w-full pt-0 pb-2.5 px-0 border-t-[none] border-b-[#ccc] border-x-[none] border-b border-solid focus:border-b-linkColor text-white;
  outline: none;
}
textarea.form-input {
  @apply resize-y;
}
.form-input:focus + label,
.form-line label.top {
  @apply text-[10px] text-linkColor -top-2.5;
}
.error-label,
.check-label {
  @apply text-[10px] absolute text-white opacity-0 invisible transition-[0.3s] duration-[all] ease-[ease] p-[3px] rounded-sm right-0 bottom-2.5;
}
.error-label {
  @apply bg-[#f75e5e];
}
.check-label {
  @apply bg-linkColor w-4 h-4 after:w-[5px] after:rotate-[20deg] after:left-1 after:bottom-1 before:w-2.5 before:rotate-[110deg] before:right-px before:top-[7px];
}
.check-label:after,
.check-label:before {
  @apply absolute content-[''] bg-white h-0.5;
}
.form-line.success .form-input {
  @apply border-b-linkColor;
}
.form-line.error label {
  @apply text-[#f75e5e];
}
.form-line.error .form-input {
  @apply text-[#4e0c0c] border-b-[#f75e5e];
}
.form-line.error .error-label,
.form-line.success .check-label {
  @apply opacity-100 visible;
}
.headline {
  @apply block text-center uppercase font-[bold] text-base tracking-[5px] relative mb-10;
}
.headline span {
  @apply relative z-[2];
}
.Link__Hover a{
  @apply transition-all duration-300 hover:text-linkColor hover:-translate-y-1
}
.form-button {
  @apply font-[bold] text-center uppercase  cursor-pointer block transition-[0.3s] duration-[all] ease-[ease] absolute tracking-[2px] px-[60px] py-2 rounded-3xl border-[none] bg-linkColor text-white bottom-10 hover:bg-[#191919] hover:shadow-[0px_10px_25px_0px_rgba(72,72,72,0.1)] hover:bottom-[45px];
  outline: none;
  /* background: linkColor; */
}
.form-button:hover {
  -webkit-box-shadow: 0px 10px 25px 0px rgba(72, 72, 72, 0.1);
  -moz-box-shadow: 0px 10px 25px 0px rgba(72, 72, 72, 0.1);
}
@media (max-width: 500px) {
  .form-container {
    @apply w-[300px] pt-[30px] pb-[100px] px-5;
  }
  .form-button {
    @apply py-[15px] bottom-[25px] hover:bottom-[30px];
  }
}

.slick-dots li button:before
{
    font-size: 14px !important;
}
/*  */



#Second__Home__Page_Blog{
  margin-top:20px;
}

/* background: linear-gradient(172deg,#172940 20%,#64f 300%); */